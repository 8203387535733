/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */

.cos-icon-button {
  width: 40px;
  height: 40px;
  background: #2D3137;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--colors-text-primary);
  border: none;

  &--transparent {
    background: transparent;
  }

  &--medium {
    height: 48px;
    width: 48px;
  }

  &--large {
    height: 56px;
    width: 56px;
  }

  &--xl {
    height: 72px;
    width: 72px;
  }

  .material-icons {
    display: grid;
    align-self: center;
    justify-self: center;
    color: var(--colors-text-secondary);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    .material-icons {
      color: rgba(255, 255, 255, 0.1);
    }
  }

  &--drag {
    cursor: grab;
  }

  &--save {
    background-color: var(--colors-button-success);
    color: var(--colors-button-success-background);

    .material-icons {
      color: var(--colors-button-success-background);
    }
  }

  &--cancel {
    background-color: var(--colors-button-cancel);
    color: var(--colors-button-cancel-background);

    .material-icons {
      color: var(--colors-button-cancel-background);
    }
  }
}

.cos-button {
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  align-items: center;
  gap: 8px;

  &--primary {
    background: var(--colors-cos-button-primary);
    color: var(--colors-text-secondary);

    &:disabled {
      color: var(--colors-text-primary);
      opacity: 0.4;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &--secondary {
    background: var(--colors-cos-button-secondary);
    color: var(--colors-text-secondary);

    &:disabled {
      color: var(--colors-text-primary);
      opacity: 0.4;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &--save {
    background-color: var(--colors-button-success);
    color: var(--colors-button-success-background);

    .material-icons {
      color: var(--colors-button-success-background);
    }
  }

  &--cancel {
    background-color: var(--colors-button-cancel);
    color: var(--colors-button-cancel-background);

    .material-icons {
      color: var(--colors-button-cancel-background);
    }
  }

  &--medium {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 40px;
    min-width: 152px;
    font-size: 14px;
    line-height: 24px;
  }

  &--small {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 32px;
    min-width: 122px;
    font-size: 12px;
    line-height: 16px;
  }
}

.cos-wide-button {
  width: 100%;
  height: 72px;
  background: var(--colors-background-ternary);
  border-radius: 4px;
  border: none;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 32px;
  align-items: center;
  align-content: center;
  padding: 16px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &__icon-container {
    width: 40px;
    height: 40px;
    background: #2D3137;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__icon {
    display: grid;
    align-self: center;
    justify-self: center;
    color: var(--colors-text-primary);
  }

  &__text {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--colors-text-secondary);
    text-align: start;
  }
}
