/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.cui-list {
  display: grid;
  grid-row-gap: var(--list-row-gap);

  &__row {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    grid-column-gap: 16px;

    border-radius: 4px;
    padding: 10px 16px;
    min-height: 64px;

    background-color: var(--colors-background-list-item);
    color: var(--colors-text-primary);

    font-family: "Rubik", sans-serif;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    .text {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__header {
    background-color: var(--colors-background-list-item);
    color: var(--colors-text-primary);

    padding: 10px 16px;
    margin-bottom: 8px;
    min-height: 40px;

    &:hover {
      cursor: default;
      opacity: 1;
    }

    .text {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__total {
    color: var(--colors-text-primary);
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &--wrap {
    white-space: pre-wrap;
  }

  &--line {
    text-decoration: line-through;
  }

  &--bold {
    font-weight: 500;
  }

  &--valid {
    color: #00bd56;
  }

  &--invalid {
    color: #e02f41;
  }

  &--white {
    color: var(--colors-text-secondary);
  }

  &__column {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 6px;
  }

  &__icon-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    grid-column-gap: 10px;
  }

  &__multiline-container {
    display: grid;
    grid-row-gap: 4px;
  }
}
