/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */

@import "scroll";
@import "borders";
@import 'white-scroll';

.mat-form-field {
  &.mat-form-field-appearance-standard {
    color: var(--colors-white);
  }

  .mat-input-element {
    caret-color: var(--colors-white);

    &::placeholder {
      color: var(--colors-text-primary);
    }

    &:disabled {
      color: var(--colors-text-primary);
    }
  }

  .mat-form-field-label {
    color: var(--colors-text-primary);
  }

  .mat-form-field-underline {
    background-color: var(--colors-grey);
  }

  .mat-form-field-ripple {
    background-color: var(--colors-grey);
  }

  .mat-select-arrow {
    transition: all 100ms linear;
    color: var(--colors-grey) !important;
  }

  &:not(.mat-form-field-disabled) {
    .mat-form-field-flex {
      &:hover {
        .mat-select-value-text {
          color: var(--colors-white) fff !important;
        }

        .mat-select-arrow {
          color: var(--colors-white) fff !important;
        }
      }
    }
  }

  .mat-select-value-text {
    transition: all 100ms linear;
    color: var(--colors-grey) !important;
  }


  &.mat-focused {
    .mat-form-field-label {
      color: var(--colors-text-primary);
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-flex {
      border-bottom: none;
    }

    .mat-form-field-prefix {
      top: 0;
    }

    .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
      border-color: var(--colors-text-primary);
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
        border-color: rgba(255, 255, 255, 0.1);
      }

      .mat-form-field-label {
        color: var(--colors-text-primary);
      }

      .mat-input-element {
        color: var(--colors-text-primary);
      }
    }

    &.mat-focused {
      .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
        border-color: var(--colors-white);
      }

      .mat-form-field-label {
        color: var(--colors-white);
      }
    }

    &.mat-form-field-invalid {
      .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
        border-color: #f44336;
      }
    }

  }
}

.mat-tooltip {
  background-color: rgba(0, 0, 0, 0.85);
  color: var(--colors-text-primary);
  font-size: 12px;
  line-height: 1.5em;

  &--pre-wrap {
    white-space: pre-wrap;
  }
}

.mat-select-panel {
  background-color: var(--colors-secondary);
}

.mat-option {
  color: var(--colors-text-primary);

  &.mat-selected {
    color: var(--colors-text-secondary);

    .mat-option-text {
      color: var(--colors-text-secondary);
    }
  }
}

.not-empty-select.mat-selected {
  color: var(--colors-text-secondary);
}

.mat-option-text {
  color: var(--colors-text-primary);
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: none;
}

.mat-form-field-ripple {
  background-color: white !important;
}

.mat-button {
  &[disabled][disabled] {
    color: var(--colors-text-primary);
  }

  .mat-stroked-button {
    border: 1px solid white !important;
  }
}

.mat-button-disabled {
  .mat-icon {
    color: var(--colors-text-primary);
  }
}

.mat-form-field-flex {
  border-bottom: 1px solid var(--colors-grey);
}

.mat-select-panel {
  @include light-scroll();
  border-radius: $border-radius;
}

.mat-form-field--no-label {
  .mat-select-arrow-wrapper {
    transform: unset !important;
  }
}

.mat-form-field--no-errors {
  .mat-form-field-wrapper {
    margin-bottom: -1.125em !important;
  }
}

.mat-menu-panel {
  background-color: var(--colors-secondary);
}

.mat-menu-item {
  color: var(--colors-text-primary);
}

.mat-menu-item[disabled] {
  color: rgba(255, 255, 255, 0.25);
}

.sort-menu .mat-menu-panel {
  border-radius: 0 !important;
  color: white !important;
  background-color: rgba(80, 80, 90, 0.5);
}

.sort-menu {
  .mat-menu-panel {
    border-radius: 0 !important;
    color: white !important;
    background-color: rgba(80, 80, 90, 0.5);
  }
}

.mat-menu-panel.sort-menu {
  color: #501010;
  background-color: #1ecd97;
  border-radius: 0;

  .mat-menu-content {
    @include white-scroll();
    background-color: #3D5AFF;
  }
}

.mat-menu-item .mat-icon-no-color {
  color: inherit;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 56px !important;
}

.mat-mini-fab .mat-button-wrapper {
  padding: 2px 0 !important;
  font-size: 12px;
}

.mat-pseudo-checkbox {
  color: var(--colors-text-primary);
}

.mat-autocomplete-panel {
  @include white-scroll();
}

.mat-select-placeholder {
  color: var(--colors-text-primary);
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: unset;
}

.mat-step-text-label {
  color: var(--colors-white);
}

.mat-tab-header-pagination-chevron {
  border-color: var(--colors-text-primary);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.2);
}

.mat-select-value {
  color: var(--colors-text-primary);
}

.mat-slide-toggle-content, .mat-slide-toggle-label {
  color: white;
}
