/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */

.cos-multiline {
  display: grid;
  grid-row-gap: 4px;
}

.cos-multi-column {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  align-items: center;
}

.cos-list-body-container {
  display: grid;
  grid-row-gap: 16px;
}

.cos-list-body-pagination {
  display: grid;
  justify-self: center;
}
