/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */

.shopify-privacy-policy {
  ol {
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 32px;

    li {
      font-size: 18px;
    }
  }

  p {
    margin-bottom: 20px;
    font-size: 18px;
  }

  span {
    font-size: inherit;
  }
}
