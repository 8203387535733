/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.drawer-panel {
  transition: all .4s;

  .mat-dialog-container {
    padding: 0;
  }
}


.mat-dialog-container {
  padding: 0;
}
