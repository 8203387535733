/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.cos-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &--primary {
    background: #67F7AE;
  }

  &--secondary {
    background: #F5333F;
  }

  &--ternary {
    background: #FF8C15;
  }
}
