/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use 'breakpoints' as *;
@import "containers";

.cui-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--colors-background-header);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding-top: 32px;
  padding-bottom: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @include responsive-padding;

  &--with-back-button {
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;
  }

  &--with-tabs {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding-bottom: 0;
  }

  &__title {
    color: var(--colors-white);
    font-size: 36px;
    font-family: "Campton", sans-serif;
    font-weight: 700;
  }

  &__subtitle {
    color: var(--colors-text-primary);
    font-size: 16px;
    font-family: "Campton", sans-serif;
    font-weight: 400;
  }

  &__label {
    display: grid;
    grid-row-gap: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  &__tabs {
    margin-bottom: 0;
  }
}

h1 {
  color: var(--colors-white);
  font-size: 36px;
  font-family: "Campton", sans-serif;
  font-weight: 700;
}

h2 {
  color: var(--colors-white);
  font-size: 28px;
  font-family: "Campton", sans-serif;
  font-weight: 700;
}

h3 {
  color: var(--colors-white);
  font-size: 20px;
  font-family: "Campton", sans-serif;
  font-weight: 700;
}

h4 {
  color: var(--colors-text-primary);
  font-size: 16px;
  font-family: "Campton", sans-serif;
  font-weight: 500;
}
