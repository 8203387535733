/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use 'node_modules/@angular/material/index' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

@include mat.core();
$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  )
));
@include mat.all-component-themes($my-theme);

@import "reset";
@import "buttons";

@import "fonts";
@import "material";
@import "fixes";
@import "drawer";

@import "cui-buttons";
@import "cui-header";
@import "cui-list";
@import "cui-status";
@import "cui-containers";
@import "cui-list-utils";
@import "cui-widget";
@import "cui-docs";
@import "cui-tabs";
@import "shopify-privacy-policy";

//cosmose UI
@import "cos/cos-text";
@import "cos/cos-multiline";
@import "cos/cos-buttons";
@import "cos/cos-circle";

:root {
  --colors-text-primary: rgba(255, 255, 255, 0.5);
  --colors-text-secondary: rgba(255, 255, 255, 1);
  --colors-text-accent: #889FFF;
  --colors-text-invalid: #FB2B50;

  --colors-button-success: rgba(43, 251, 214, 0.1);
  --colors-button-success-background: #2BFBD6;

  --colors-button-cancel: rgba(251, 43, 80, 0.2);
  --colors-button-cancel-background: #FB2B50;

  --colors-primary: #111217;
  --colors-secondary: #171B1F;
  --colors-ternary: rgba(255, 255, 255, 0.03);
  --colors-quaternary: rgba(255, 255, 255, 0.05);

  --colors-grey: rgba(255, 255, 255, 0.5);
  --colors-grey-primary: rgba(255, 255, 255, 0.1);
  --colors-grey-secondary: rgba(255, 255, 255, 0.2);
  --colors-text-grey: rgba(255, 255, 255, 0.2);
  --colors-datepicker: #111217;

  --colors-white: #FFFFFF;
  --colors-collapse-button: #586077;
  --colors-sidenav: #22252B;
  --colors-sidenav-container: #111217;
  --colors-error: #FB2B50;

  --colors-border-primary: rgba(255, 255, 255, 0.5);
  --colors-border-secondary: #ffffff;
  --colors-border-ternary: rgba(255, 255, 255, 0.2);
  --colors-border-quaternary: rgba(255, 255, 255, 0.1);

  --colors-background-disabled: rgba(0, 0, 0, 0.1);

  --colors-dialog: #111217;
  --colors-hint: #4C35FF;
  --colors-contrast: #ae00ff;

  --colors-permission-excluded: #A92759;
  --colors-permission-included: #0D8683;
  --colors-permission-default: #857BFF;

  --transition: all ease-in-out 0.2s;

  --colors-background-primary: #111217;
  --colors-background-secondary: #171B1F;
  --colors-background-ternary: #22252B;
  --colors-background-quaternary: #2D3137;
  --colors-background-error: rgba(218, 54, 72, 0.2);

  --colors-background-list-item: #22252B;
  --colors-background-header: #171B1F;
  --colors-background-dialog: #22252B;

  --colors-separator: rgba(255, 255, 255, 0.2);

  --colors-new-region-select: #7A2BFF;
  --colors-new-toggle-checked: #7A2BFF;

  --colors-checkbox: #fff;
  --colors-checkbox-stroke: #000;

  --colors-new-image-border: rgba(255, 255, 255, 0.05);
  --colors-new-icon-button-background: rgba(0, 0, 0, 0.2);
  --colors-button-disabled-opacity: 0.5;

  --colors-status-success: #2BFBD6;
  --colors-status-success-background: rgba(0, 255, 212, 0.1);
  --colors-status-fail: #FB2B50;
  --colors-status-fail-background: rgba(251, 43, 80, 0.1);
  --colors-status-default: rgba(255, 255, 255, 0.05);
  --colors-status-default-background: rgba(255, 255, 255, 0.5);
  --colors-status-primary: #A0CECB;
  --colors-status-primary-background: rgba(160, 206, 203, 0.5);

  --list-row-gap: 4px;
  --list-item-padding: 16px;
  --list-item-border-radius: 2px;

  --action-buttons-gap: 20px;
  --colors-grey: #808080;

  --cropper-overlay-color: var(--colors-background-ternary);

  --colors-cos-button-primary: #7040FF;
  --colors-cos-button-secondary: rgba(255, 255, 255, 0.05);

  --border-radius: 4px;
  --separator: 1px solid rgba(255, 255, 255, 0.1);

  --cos-colors-accent: #7040FF;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  color: var(--colors-text-primary);
  background-color: var(--colors-primary);
  font-size: 16px;
}

* {
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: inherit;
  outline: none;
}

//chrome autocomplete input color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//chrome autocomplete default font
input:-webkit-autofill::first-line {
  font-size: 16px !important;
}

.upload-dialog .mat-dialog-container {
  border-radius: 8px;
  box-shadow: none;
  background-color: var(--colors-dialog);
}

.mat-dialog-container {
  background-color: var(--colors-dialog);
  padding: 0 !important;
}

.centered {
  display: flex;
  justify-content: center;
}

.mat-autocomplete-panel {
  background-color: var(--colors-secondary);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background-color: var(--colors-secondary);
}

.mat-checkbox-frame {
  border-color: var(--colors-border-primary);
}

.mat-snack-bar-container {
  margin: 64px 0 0 !important;
  padding: 20px !important;
}

.custom-snackbar {
  padding: 48px;
  border-radius: 4px;
  background-color: #7A2BFF;
  top: 200px;
  display: grid;
  justify-content: center;
  align-items: center;
  min-width: 50vw !important;
  max-width: 80vw !important;
}

.mat-form-field {
  color: var(--colors-text-secondary);
}

.required-for-drop-icon {
  color: var(--colors-error);
  cursor: pointer;
}

::ng-deep {
  .mat-tooltip.required-for-drop-icon {
    white-space: pre-wrap;
  }
}

p {
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.cui-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}

gallery {
  background-color: var(--colors-background-ternary) !important;
}

.ngx-ic-cropper {
  .ngx-ic-move {
    //border: 32px solid rgba(204, 252, 228, 0.7) !important;
  }
}

.ngx-ic-move-active {
  border: 32px solid rgba(204, 252, 228, 0.7) !important;
}

.mat-gallery-dialog {
  background: rgba(0, 0, 0, 0.8);
}

.custom-menu {
  padding: 8px;
}

a {
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}

.loading-text {
  color: var(--colors-text-secondary);
}

.mat-error {
  font-size: 12px;
}

.mat-radio-outer-circle {
  border: white;
}

.mat-radio-inner-circle {
  border: white;
}

.mat-radio-container {
  border: white;
}

:root .my-custom-ngx-diff-theme {
  --ngx-diff-border-color: #888888;
  --ngx-diff-font-size: 1rem;
  --ngx-diff-font-family: Consolas, Courier, monospace;
  --ngx-diff-font-color: #000000;
  --ngx-diff-line-number-font-color: #484848;
  --ngx-diff-line-number-hover-font-color: #ffffff;
  --ngx-diff-selected-border-color: #ff8000;
  --ngx-diff-inserted-background-color: #9dff97;
  --ngx-diff-deleted-background-color: #ff8c8c;
  --ngx-diff-equal-background-color: #ffffff;
  --ngx-diff-margin-background-color: #dedede;
  --ngx-diff-line-number-width: 2rem;
  --ngx-diff-border-width: 1px;
  --ngx-diff-line-left-padding: 1rem;
  --ngx-diff-bottom-spacer-height: 1rem;
}
