/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@mixin white-scroll() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--colors-grey-primary);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colors-text-secondary);
    border-radius: 4px;
  }
}
