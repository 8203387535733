/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.cui-widget {
  display: grid;
  background-color: var(--colors-background-ternary);
  grid-template-columns: repeat(4, 1fr);
  padding: 36px;

  &__element {
    display: grid;
    grid-column-gap: 16px;
    align-items: center;
    align-content: start;
  }

  &__label {
    font-size: 14px;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    color: var(--colors-text-primary);
  }

  &__data {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &__value {
    color: var(--colors-white);
    font-family: Rubik, sans-serif;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 500;
  }

  &__currency {
    color: var(--colors-text-primary);
    font-family: Rubik, sans-serif;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 300;
    margin-right: 10px;
  }
}
