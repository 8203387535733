/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */


.documentation {
  color: var(--colors-text-secondary);

  ol {
    padding: 0 0 0 20px;
    list-style-type: circle;
  }

  li {
    padding: 20px 0 0 20px;
    list-style-type: decimal;
  }

  .sublist {
    li {
      list-style-type: lower-alpha;
    }
  }

  strong {
    font-weight: bolder;
  }

  i {
    font-style: italic;
  }

  section {
    display: grid;
    grid-row-gap: 10px;
  }

  img {
    width: 100%;
  }
}
