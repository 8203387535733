/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@use 'breakpoints' as *;

@mixin responsive-padding {
  padding-right: 16px;
  padding-left: 16px;

  @media (min-width: $breakpoint-xs) {
    padding-right: 32px;
    padding-left: 32px;
  }

  @media (min-width: $breakpoint-sm) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (min-width: $breakpoint-md) {
    padding-right: 70px;
    padding-left: 70px;
  }

  @media (min-width: $breakpoint-lg) {
    padding-right: 100px;
    padding-left: 100px;
  }

  @media (min-width: $breakpoint-xl) {
    padding-right: 180px;
    padding-left: 180px;
  }

  @media (min-width: $breakpoint-uhd) {
    padding-right: 780px;
    padding-left: 780px;
  }
}

@mixin responsive-margin {
  margin-right: 16px;
  margin-left: 16px;

  @media (min-width: $breakpoint-xs) {
    margin-right: 32px;
    margin-left: 32px;
  }

  @media (min-width: $breakpoint-sm) {
    margin-right: 50px;
    margin-left: 50px;
  }

  @media (min-width: $breakpoint-md) {
    margin-right: 140px;
    margin-left: 140px;
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: 260px;
    margin-left: 260px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-right: 380px;
    margin-left: 380px;
  }

  @media (min-width: $breakpoint-uhd) {
    margin-right: 780px;
    margin-left: 780px;
  }
}

.container {
  margin-top: 24px;
  margin-bottom: 24px;
  @include responsive-padding;
}
